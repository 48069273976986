<template>
  <section>
    <!-- <button @click="scrollToElement">bottom</button> -->
    <div v-if="loadingPage" class="circle-loader"></div>

    <div class="row" v-else>
      <div class="col-lg-12 grid-margin stretch-card">
        <b-card ref="editarContato">
          <b-card-title>
            <b-row class="justify-content-between1">
              <h3 class="page-title mb-n2" style="padding-left: 0.75rem">
                Editar Contato
              </h3>
            </b-row>
          </b-card-title>
          <h3 class="page-title mb-n2" style="font-size: 0.99rem">
            Nome: <span class="text-primary">{{ oldNameContat }} </span>
          </h3>

          <h3 class="page-title mt-3" style="font-size: 0.99rem">
            Empresa: <span class="text-primary">{{ oldCompany }}</span>
          </h3>

          <form class="forms-sample mt-5" @submit.stop.prevent="addContato">
            <!-- <div class="col-4"> -->

            <b-form-group
              label="Empresa"
              :class="showWhiteMode ? 'label__black' : ''"
            >
              <multiselect
                v-model="empresa"
                :options="options"
                placeholder="Nome da Empresa ou CNPJ"
                selectLabel="Enter para selecionar"
                selectedLabel="Selecionado"
                deselectLabel="Enter para remover"
                @search-change="asyncFind"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                :searchable="true"
                :internal-search="false"
                :loading="isLoading"
                :label="'razao_social'"
              >
                <template slot="noOptions">Lista está vazia.</template>
              </multiselect>

              <span class="invalid-feedback d-inline" v-if="showEmpresaError">
                Preenchimento obrigatório</span
              >
            </b-form-group>

            <b-form-group
              label="Nome"
              :class="showWhiteMode ? 'label__black' : ''"
            >
              <input
                v-model="newContact.name"
                class="form-control"
                placeholder="Nome"
              />

              <span class="invalid-feedback d-inline" v-if="showNameError"
                >Preenchimento obrigatório</span
              >
            </b-form-group>

            <b-form-group
              label="Cargo"
              :class="showWhiteMode ? 'label__black' : ''"
            >
              <input
                v-model="newContact.cargo"
                class="form-control"
                placeholder="Cargo"
              />
            </b-form-group>

            <b-form-group
              label="Departamento"
              :class="showWhiteMode ? 'label__black' : ''"
            >
              <multiselect
                v-model="departamentoSelected"
                :options="departamentos"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="false"
                placeholder="Selecionar Depto."
                label="nome"
                track-by="id"
                selectLabel="Enter para selecionar"
                selectedLabel="Selecionado"
                deselectLabel="Enter para remover"
                @open="carregarDepartamentos"
                :loading="carregandoDepartamentos"
              >
                <template slot="noOptions">Lista está vazia.</template>
              </multiselect>
            </b-form-group>

            <b-form-group
              label="Nível Hierárquico"
              :class="showWhiteMode ? 'label__black' : ''"
            >
              <multiselect
                v-model="nivelHierarquicosSelected"
                :options="nivelHierarquicos"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="false"
                placeholder="Selecionar Nível"
                label="nome"
                track-by="id"
                selectLabel="Enter para selecionar"
                selectedLabel="Selecionado"
                deselectLabel="Enter para remover"
                @open="carregarNivelHierarquicos"
                :loading="carregandoNivelHierarquicos"
              >
                <template slot="noOptions">Lista está vazia.</template>
              </multiselect>
            </b-form-group>

            <b-form-group
              label="Linkedin "
              :class="showWhiteMode ? 'label__black' : ''"
            >
              <input
                v-model="newContact.linkedin"
                class="form-control"
                placeholder="Linkedin"
              />
            </b-form-group>

            <b-form-group
              label="Telefone"
              :class="showWhiteMode ? 'label__black' : ''"
              class="mb-0"
            >
              <span ref="telefone">
                <!-- ref para scroll automatico --- vazio   -->
              </span>

              <div class="todo-list">
                <!-- <form @submit.prevent="addEmail" > -->

                <form @submit.prevent="addPhone">
                  <div class="add-items d-flex" style="margin-bottom: 0.6rem">
                    <TheMask
                      v-model.trim="newPhone"
                      :mask="[
                        '+## (##) ####-####',
                        '+## (##) #####-####',
                        '+### (###) #####-####',
                        '+### ### ############',
                      ]"
                      type="text"
                      class="form-control todo-list-input"
                      placeholder="Formato internacional +5511991929394"

                      ref="themask"
                      :style="warning ? 'border:1px solid var(--warning)' : ''"

                    />
                    <b-button
                      variant="primary"
                      class="add todo-list-add-btn"
                      id="add-task"
                      :disabled="loaddingSetFone"
                      type="submit"

                      title="Salvar Telefone"
                    >
                      <span class="d-flex" v-if="loaddingSetFone"
                        >Salvando
                        <b-spinner small type="grow" class="ml-2"></b-spinner
                      ></span>
                      <span v-else>Salvar</span>
                    </b-button>
                  </div>
                </form>
                <div class="list-wrapper">
                  <ul class="d-flex flex-column-reverse todo-list">
                    <li
                      v-for="telefone in phones"
                      :key="telefone.id"
                      style="justify-content: space-between !important;"
                    >
                      <div class="form-check w-100 pl-1">
                        <label class="form-check-label m-0 text-primary">
                          {{ telefone.telefone }}
                        </label>
                      </div>

                      <i
                        @click="removePhone(telefone)"
                        class="mdi mdi-delete remove mr-4 text-danger "
                        style="color: var(--danger) !important"
                      ></i>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- <div
                v-for="(telefone, index) in newContact.telefones"
                :key="index"
                class="d-flex mt-1"
                :style="
                  !showWhiteMode ? 'background: #2a3038' : 'background: #fff'
                "
              >
                <input
                  class="form-control"
                  type="tel"
                  placeholder="Formato internacional +5511991929394"
                  v-model="newContact.telefones[index].telefone"
                  :style="warning ? 'border:1px solid var(--warning)' : ''"
                />

                <button
                  class="btn"
                  @click="removeTelefone(index)"
                  type="button"
                >
                  <i class="mdi mdi-delete text-danger"></i>
                </button>

                <button
                  class="btn"
                  @click="removeTelefone(index)"
                  type="button"
                >
                  <i class="fa fa-save text-primary"></i>
                </button>
              </div> -->

              <!-- <div class="d-flex justify-content-end mt-2">
                <button
                  class="btn btn-success"
                  v-on:click="newContact.telefones.push('')"
                  type="button"
                >
                  +
                </button>
              </div> -->
            </b-form-group>

            <b-form-group
              label="Email"
              :class="showWhiteMode ? 'label__black' : ''"
              class="mb-0"
            >
              <div class="todo-list">
                <!-- <form @submit.prevent="addEmail"> -->
                <form @submit.prevent="addEmail">
                  <div class="add-items d-flex" style="margin-bottom: 0.6rem">
                    <input
                      v-model.trim="newEmail"
                      type="email"
                      class="form-control todo-list-input"
                      placeholder="E-mail"
                      style="background:transparent"
                      required
                      :style="warning ? 'border:1px solid var(--warning)' : ''"

                    />

                    <b-button
                      variant="primary"
                      class="add todo-list-add-btn"
                      id="add-task"
                      type="submit"
                      :disabled="loaddingSetEmail"
                      title="Salvar E-mail"

                    >
                      <span class="d-flex" v-if="loaddingSetEmail"
                        >Salvando
                        <b-spinner small type="grow" class="ml-2"></b-spinner
                      ></span>
                      <span v-else>Salvar</span>
                    </b-button>
                  </div>
                </form>
                <div class="list-wrapper">
                  <ul class="d-flex flex-column-reverse todo-list">
                    <li
                      v-for="email in emails"
                      :key="email.id"
                      style="justify-content: space-between !important;"
                    >
                      <div class="form-check w-100 pl-1">
                        <label class="form-check-label m-0 text-primary">
                          {{ email.email }}<i class="input-helper"></i
                        ></label>
                      </div>

                      <i
                        @click="removeEmail(email)"
                        class="mdi mdi-delete remove mr-4 text-danger "
                        style="color: var(--danger) !important"
                      ></i>
                    </li>
                  </ul>
                </div>
              </div>
            </b-form-group>

            <div class="d-flex justify-content-end">
              <b-button
                type="submit"
                variant="primary"
                class="mr-2 my-5"
                :disabled="loading"
              >
                <template v-if="loading">
                  <b-spinner small type="grow"></b-spinner>
                  Processando...
                </template>
                <template v-else> Concluir </template>
              </b-button>
            </div>
            <!-- </div> -->
          </form>
        </b-card>
      </div>
    </div>

    <!-- <div ref="telefone">
        <h1>go to</h1>
      </div> -->
  </section>
</template>

<script>
// import empresaService from "../../../services/empresa.service";
import empresaService from "@/services/empresa.service";
import DepartamentoService from "@/services/departamento.service";
import NivelHierarquicoService from "@/services/nivelhieararquico.service";
// import LeadService from "@/services/lead.service";
import ContatoService from "@/services/contato.service";
import { TheMask } from "vue-the-mask";

import { mapState } from "vuex";

export default {
  props: ["id", "new_contact"],
  components: { TheMask },
  data() {
    return {
      // showModal: false,
      newContact: {
        name: "",
        cargo: "",
        linkedin: "",
      },
      loading: false,
      isLoading: false, //
      empresa: null,
      options: [],

      //departamentos abaixo
      departamentos: [],
      departamentoSelected: null,
      carregandoDepartamentos: false,

      // nivelHierarquicos
      nivelHierarquicos: [],
      nivelHierarquicosSelected: null,
      carregandoNivelHierarquicos: false,

      loadingPage: true,

      oldNameContat: "",
      oldCompany: "",

      showNameError: false,
      showEmpresaError: false,

      warning: false,

      newPhone: "",
      phones: [],
      loaddingSetFone: false,

      newEmail: "",
      emails: [],
      loaddingSetEmail: false,
    };
  },

  methods: {
    asyncFind(name) {
      this.isLoading = true;

      if (isNaN(Number(name[0]))) {
        return this.findCompanyByName(name);
      }

      this.findCompanyByCNPJ(name);
    },

    findCompanyByName(name) {
      this.isLoading = true;

      empresaService
        .getAll({ q: name })
        .then((res) => {
          // console.log(res.data.data);
          this.options = res.data.data;

          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
    findCompanyByCNPJ(CNPJ) {
      this.isLoading = true;

      empresaService
        .getAll({ cnpjs: CNPJ })
        .then((res) => {
          //   console.log(res.data.data);
          this.options = res.data.data;

          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },

    carregarDepartamentos() {
      if (this.departamentos.length == 0) {
        this.carregandoDepartamentos = true;
        DepartamentoService.getAll().then((response) => {
          this.departamentos = response.data;
          this.carregandoDepartamentos = false;
          // console.log(response.data);
        });
      }
    },
    carregarNivelHierarquicos() {
      this.carregandoNivelHierarquicos = true;
      NivelHierarquicoService.getAll().then((response) => {
        this.nivelHierarquicos = response.data;
        this.carregandoNivelHierarquicos = false;
      });
    },

    setContato() {
      ContatoService.get(this.id)
        .then((response) => {
          // console.log(response.data);
          if (response.data.empresa) {
            const empresa_id = response.data.empresa.id;
            this.setEmpresa(empresa_id);
          }

          const contato = response.data;
          // this.oldNameContat = contato.nome;
          // this.oldNameContat = contato.nome +" - " + `${contato.empresa  ? contato.empresa.empresa_base.razao_social : contato.empresa_nome} `;
          this.oldNameContat = contato.nome; // +" - " + `${contato.empresa  ? contato.empresa.empresa_base.razao_social : contato.empresa_nome} `;
          this.oldCompany = `${
            contato.empresa
              ? contato.empresa.empresa_base.razao_social
              : contato.empresa_nome
          } `;

          this.newContact.name = contato.nome;
          this.newContact.cargo = contato.cargo;
          this.newContact.linkedin = contato.linkedin;
          // this.newContact.telefones = contato.contato_telefones.map(telefone => telefone.telefone)
          // this.newContact.emails = contato.contato_emails.map(email => email.email)

          this.departamentoSelected = contato.departamento;

          this.nivelHierarquicosSelected = contato.nivel_hierarquico;

          this.loadingPage = false;
        })
        .catch((e) => {
          console.log(e);
          this.loadingPage = false;
        });
    },

    setEmpresa(empresa_id) {
      empresaService
        .get(empresa_id)
        .then((response) => {
          this.empresa = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    addContato() {
      if (!this.validaForm()) return;
      this.loading = true
      const data = {
        empresa_id: this.empresa.id,
        nome: this.newContact.name,
        cargo: this.newContact.cargo,
        departamento_id: this.departamentoSelected
          ? this.departamentoSelected.id
          : null,
        nivel_hierarquico_id: this.nivelHierarquicosSelected
          ? this.nivelHierarquicosSelected.id
          : null,
        linkedin: this.newContact.linkedin,
      };

      // console.warn(this.empresa.cnpj);

      ContatoService.saveOrUpdate(data, this.id)
        .then((res) => {
          this.makeToast("success", "Salvo", res.data.message);
          this.setContato();
          this.loading = false

        })
        .catch((erro) => {
          console.log(erro);
          this.makeToast("danger", "Falha", "Falha ao tentar editar  contato");
          this.loading = false

        });

      this.showNameError = false;
      this.showEmpresaError = false;
    },

    validaForm() {
      if (!this.newContact.name) {
        this.showNameError = true;
      }
      if (!this.empresa) {
        this.showEmpresaError = true;
      }

      if (!this.empresa || !this.newContact.name) return false;

      return true;
    },

    makeToast(variant = null, title, message, dalay) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
        autoHideDelay: dalay ? dalay : undefined,
      });
    },

    

    async getPhones() {
      try {
        const params = {
          contato_id: this.id,
        };

        const res = await ContatoService.getPhones(params);
        // console.log(res.data);

        this.phones = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    async addPhone() {
      if (this.newPhone.length < 5) return;
      this.loaddingSetFone = true;
      const data = { contato_id: this.id, telefone: "+" + this.newPhone };

      try {
        const res = await ContatoService.addPhones(data);
        this.makeToast("success", "Salvo", res.data.message, 1000);
        this.getPhones();
        this.loaddingSetFone = false;
        this.newPhone = "";

        this.$refs.themask.refresh();

        // themask
      } catch (error) {
        console.error(error);
        this.loaddingSetFone = false;
      }
    },

    async removePhone(telefone) {
      // console.log("chamad ", telefone);

      const confirm = await this.$swal({
        title: telefone.telefone,
        text: "Confirma a remoção desse número?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        cancelButtonText: "Não",
      });

      if (confirm.isConfirmed) {
        try {
          const res = await ContatoService.deletePhone(telefone.id);
          // console.log(res.data.message);
          this.makeToast("success", "Removido", res.data.message, 1000);
          this.getPhones();
        } catch (error) {
          console.log(error);
        }
      }
    },

    // ------

    async addEmail() {
      if (this.newEmail.length < 5) return;
      this.loaddingSetEmail = true;

      const data = { contato_id: this.id, email: this.newEmail };

      try {
        const res = await ContatoService.addEmails(data);
        // console.log(res.data.message);
        this.makeToast("success", "Salvo", res.data.message, 1000);
        this.getEmails();
        this.loaddingSetEmail = false;
        this.newEmail = "";
      } catch (error) {
        console.log(error);
        this.loaddingSetEmail = false;
      }
    },

    async getEmails() {
      const params = {
        contato_id: this.id,
      };

      try {
        const res = await ContatoService.getEmails(params);
        // console.log(res.data);
        this.emails = res.data;
      } catch (error) {
        console.log(error);
      }
    },

    async removeEmail(email) {

      const confirm = await this.$swal({
        title: email.email,
        text: "Confirma a remoção desse E-mail?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        cancelButtonText: "Não",
      });

      if (confirm.isConfirmed) {
        try {
          const res = await ContatoService.deleteEmail(email.id);
          this.makeToast("success", "Removido", res.data.message, 1000);

          this.getEmails();
        } catch (error) {
          console.error(error);
        }
      }
    },

    scrollToElement() {
      if (this.new_contact) {
        this.warning = true;
        setTimeout(() => {
          const el = this.$refs.telefone;
          if (el) {
            el.scrollIntoView({ behavior: "smooth" });
            this.makeToast(
              "success",
              "Cadastrado",
              "Contato cadastrado com sucesso!"
            );

            setTimeout(() => {
              this.warning = false;
            }, 5000);
          }
        }, 800);
      }
    },


  },
  created() {
    this.setContato();
  },
  mounted() {
    this.findCompanyByName();
    this.carregarDepartamentos();
    this.carregarNivelHierarquicos();

    // console.log(this.new_contact);

    this.scrollToElement();

    this.getPhones();

    this.getEmails();
  },
  computed: {
    ...mapState(["showWhiteMode"]),
  },
  watch: {
    value() {
      //   console.log(this.value);
    },

    options() {
      //   console.log("mudou ", this.options);
    },
    departamentoSelected() {
      // console.log(this.departamentoSelected);
    },
  },
};
</script>

<style>
.label__black {
  color: rgb(0, 0, 0);
}
</style>

<style scoped lang="scss">
section {
  max-width: 80%;
  margin: auto;
}
.forms-sample {
  /* max-width: 80%; */
  margin: auto;

  button {
    margin-right: 0 !important;
  }
}
</style>
